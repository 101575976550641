import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { ACTION_HINT_URL_KEY } from '../../email-ack.constants'
import { EaDecisionEnum, EaFormFields } from '../../email-ack.types'

import {
  useAcknowledgeReferralWithAcceptance,
  useAcknowledgeReferralWithRejection,
} from '@/hooks/useAcknowledgeReferral'
import { ReferralDto } from '~shared/types'

export const SubmitAcknowledgementButton = ({
  referral,
}: {
  referral: ReferralDto
}) => {
  const { handleSubmit, watch } = useFormContext<EaFormFields>()
  const { referralId } = useParams()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('auth')

  const currentDecision = watch('decision')
  const acceptMutation = useAcknowledgeReferralWithAcceptance()
  const rejectMutation = useAcknowledgeReferralWithRejection()

  const onSubmit = useCallback(
    async (fields: EaFormFields) => {
      const toastCallback = {
        onSuccess: () => {
          toast({
            status: 'success',
            description: 'Response saved.',
          })
          searchParams.delete(ACTION_HINT_URL_KEY)
          setSearchParams(searchParams)
        },
        onError: (error: Error) => {
          toast({
            status: 'error',
            description: error.message ?? 'Something went wrong.',
          })
        },
      }
      if (fields.decision === EaDecisionEnum.ACCEPT) {
        const date = dayjs(fields.date).format('YYYY-MM-DD')
        const appointmentTime = dayjs(`${date} ${fields.time}`).valueOf()

        acceptMutation.mutate(
          {
            referralId,
            token,
            appointmentTime,
            noteContent: fields.noteContent,
          },
          toastCallback,
        )
      } else {
        rejectMutation.mutate(
          {
            referralId,
            token,
            rejectionMessage: fields.rejectionMessage ?? '',
          },
          toastCallback,
        )
      }
    },
    [
      referralId,
      acceptMutation,
      rejectMutation,
      toast,
      token,
      searchParams,
      setSearchParams,
    ],
  )

  const buttonText = useMemo(() => {
    if (referral.status === 'pending-email-ack') return 'Submit'
    if (currentDecision === EaDecisionEnum.ACCEPT) return 'Reschedule'
    return 'Submit'
  }, [currentDecision, referral.status])

  return (
    <Button
      onClick={handleSubmit(onSubmit)}
      isLoading={acceptMutation.isPending || rejectMutation.isPending}
    >
      {buttonText}
    </Button>
  )
}
