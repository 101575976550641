import { RouteObject } from 'react-router-dom'

import { AppLayout } from '@/app/AppLayout'
import { ErrorCard } from '@/components/ErrorCard'
import { NotFoundPage } from '@/components/NotFoundPage'
import { EmailAckPage } from '@/features/email-ack'
import { PublicReferralPage } from '@/features/public-referral'

export const appRoutes: RouteObject[] = [
  {
    element: <AppLayout />,
    errorElement: <ErrorCard />,
    children: [
      {
        path: 'referrals/:referralId',
        element: <PublicReferralPage />,
      },
      {
        path: 'referrals/:referralId/acknowledge',
        element: <EmailAckPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]
