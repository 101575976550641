import { BiDetail } from 'react-icons/bi'
import { HStack, Text, VStack } from '@chakra-ui/react'

import { Info } from '../Info'

import { ReferralDto } from '~shared/types'

export const PatientDetails = ({ referral }: { referral: ReferralDto }) => {
  return (
    <VStack w="full" align="start" spacing="1rem">
      <HStack color="base.content.medium">
        <BiDetail fontSize="20px" />
        <Text textStyle="subhead-3" fontSize="16px">
          DETAILS
        </Text>
      </HStack>
      <PatientDetailsBody referral={referral} />
    </VStack>
  )
}

export const PatientDetailsBody = ({ referral }: { referral: ReferralDto }) => {
  return (
    <>
      <Info section="Patient" title={referral.patient.name}>
        <Text>{referral.patient.uin}</Text>
      </Info>
      <Info section="Referring GP" title={referral.sender.name}>
        <Text>{referral.referringDoctor.name}</Text>
      </Info>
      <Info
        section="Referral Type"
        title={referral.isSubsidised ? 'Subsidised SOC' : 'Private SOC'}
      />
    </>
  )
}
