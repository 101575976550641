import { Controller, useFormContext } from 'react-hook-form'
import { BiCommentEdit } from 'react-icons/bi'
import {
  FormControl,
  HStack,
  RadioGroup,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import {
  FormErrorMessage,
  Infobox,
  Radio,
} from '@opengovsg/design-system-react'

import {
  EaDecisionEnum,
  EaFormFields,
  requiredRule,
} from '../../email-ack.types'

import { ReferralDto } from '~shared/types'

export const DecisionField = ({ referral }: { referral: ReferralDto }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<EaFormFields>()

  const decision = watch('decision')

  return (
    <FormControl isInvalid={!!errors.decision}>
      <VStack align="stretch" spacing="1rem">
        <HStack color="base.content.medium">
          <BiCommentEdit fontSize="20px" />
          <Text textStyle="subhead-3" fontSize="16px">
            REVIEW
          </Text>
        </HStack>
        <Controller
          name="decision"
          rules={requiredRule}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value} onChange={onChange}>
              <Wrap w="full">
                <WrapItem flexGrow={1} minWidth="0">
                  <Radio variant="radioCard" value={EaDecisionEnum.ACCEPT}>
                    Accept referral
                  </Radio>
                </WrapItem>
                <WrapItem flexGrow={1} minWidth="0">
                  <Radio variant="radioCard" value={EaDecisionEnum.REJECT}>
                    Reject referral
                  </Radio>
                </WrapItem>
              </Wrap>
            </RadioGroup>
          )}
        />
        <FormErrorMessage>{errors.decision?.message}</FormErrorMessage>
        {decision === EaDecisionEnum.REJECT && referral.timeslotStartAt && (
          <Infobox variant="warning">{`The appointment will be cancelled. By rejecting, you confirm that the patient has been informed of the appointment cancellation and reason.`}</Infobox>
        )}
      </VStack>
    </FormControl>
  )
}
