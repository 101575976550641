import { useEffect } from 'react'

import { PublicReferralDto } from '~shared/types'
import { uinFilter } from '~shared/utils/uin-filter'

/** Reactively sets HTML title to "Kenneth Sng - 222H" */
export const useNamedDocumentTitle = (
  referral: PublicReferralDto | undefined,
) => {
  useEffect(() => {
    if (!referral) return
    document.title = `${referral.patient.name} - ${uinFilter(
      referral.patient.uin,
    )}`
  }, [referral])
}
