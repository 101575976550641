export type NoteDto = {
  id: string
  content: string
  authorHciCode: string
  authorName: string
  createdAt: number
  updatedAt: number
}

export const MAX_NOTE_LENGTH = 8000
