import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, Text, Wrap, WrapItem } from '@chakra-ui/react'
import {
  DatePicker,
  FormErrorMessage,
  FormLabel,
  SingleSelect,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { EaFormFields, requiredRule } from '../../email-ack.types'
import { generateTimeOptions } from '../../email-ack.utils'

export const DateTimeField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EaFormFields>()

  return (
    <>
      <Text textStyle="subhead-1" color="base.content.strong">
        Appointment scheduled on
      </Text>
      <Wrap w="full">
        <WrapItem flexGrow={1} minWidth="0">
          <FormControl isInvalid={!!errors.date}>
            <FormLabel isRequired>Date</FormLabel>
            <Controller
              name="date"
              rules={requiredRule}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  isDateUnavailable={(d) => dayjs(d).isBefore(dayjs(), 'd')}
                  showTodayButton={false}
                />
              )}
            />
            <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
          </FormControl>
        </WrapItem>
        <WrapItem flexGrow={2} minWidth="0">
          <FormControl isInvalid={!!errors.time}>
            <FormLabel isRequired>Time</FormLabel>
            <Controller
              name="time"
              rules={requiredRule}
              control={control}
              render={({ field: { onChange, value } }) => (
                <SingleSelect
                  name="time"
                  colorScheme="gray"
                  placeholder="Select Time"
                  items={generateTimeOptions()}
                  value={value}
                  onChange={onChange}
                  size="md"
                  isClearable={false}
                />
              )}
            />
            <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
          </FormControl>
        </WrapItem>
      </Wrap>
    </>
  )
}
