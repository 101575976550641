import { FormProvider, useForm } from 'react-hook-form'

import { EaDecisionEnum, EaFormFields } from '../../email-ack.types'

import { AcknowledgeChas } from './AcknowledgeChas'
import { AcknowledgeUnsubsidised } from './AcknowledgeUnsubsidised'

import { ReferralDto } from '~shared/types'

export const Acknowledge = ({ referral }: { referral: ReferralDto }) => {
  const formMethods = useForm<EaFormFields>({
    defaultValues: { decision: EaDecisionEnum.ACCEPT, time: '' },
    mode: 'onSubmit',
  })

  return (
    <FormProvider {...formMethods}>
      {referral.isSubsidised ? (
        <AcknowledgeChas referral={referral} />
      ) : (
        <AcknowledgeUnsubsidised referral={referral} />
      )}
    </FormProvider>
  )
}
