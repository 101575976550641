import { ReactNode, useEffect } from 'react'

import { initMonitoring } from '@/services'

export const DdRum = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    initMonitoring({ browserLanguage: navigator.language })
  }, [])

  return <>{children}</>
}
