import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@opengovsg/design-system-react'

import { EaFormFields, requiredRule } from '../../email-ack.types'

export const RejectField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EaFormFields>()

  return (
    <FormControl isInvalid={!!errors.rejectionMessage}>
      <FormLabel
        isRequired
        description="We will send this to the GP so they know how to follow up with the patient. Thank you!"
      >
        Reason for rejection
      </FormLabel>
      <Controller
        name="rejectionMessage"
        rules={requiredRule}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Textarea
            value={value}
            onChange={onChange}
            placeholder="Enter your reason here"
          />
        )}
      />
      <FormErrorMessage>{errors.rejectionMessage?.message}</FormErrorMessage>
    </FormControl>
  )
}
