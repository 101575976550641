import { useState } from 'react'
import { BiZoomIn, BiZoomOut } from 'react-icons/bi'
import { ButtonGroup, ButtonGroupProps } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

export const ZoomButtonGroup = ({
  onZoomOut,
  onZoomIn,
  ...props
}: {
  onZoomOut: () => void
  onZoomIn: () => void
} & ButtonGroupProps) => {
  return (
    <ButtonGroup colorScheme="neutral" variant="outline" {...props}>
      <IconButton
        aria-label="zoom-out"
        icon={<BiZoomOut />}
        onClick={onZoomOut}
      />
      <IconButton aria-label="zoom-in" icon={<BiZoomIn />} onClick={onZoomIn} />
    </ButtonGroup>
  )
}

export const useZoomState = () => {
  const [zoom, setZoom] = useState(100)
  return {
    zoom,
    onZoomOut: () => setZoom(Math.max(zoom - 10, 10)),
    onZoomIn: () => setZoom(Math.min(zoom + 10, 200)),
  }
}
