import {
  extractStringArrayByQuestion,
  extractStringByQuestion,
} from '../utils/extract-value-by-question-key'

import { PublicReferralDto } from '~shared/types'

export type ChasFormPatientDetails = Partial<PublicReferralDto['patient']> & {
  healthierSGEnrolmentStatus?: string
  clinicEnrolmentStatus?: string
  cardType?: string
}

export type ChasFormReferralDetails = {
  referralDate?: string
  referralType?: string
  fastTrackReferralReason?: string
  referredInstitutionOrProvider?: string
  specialtyOrService?: string
  referralRelation?: string[]
  diagnosis?: string
  reasons?: string
  treatmentOrManagementByGp?: string
  medicalHistory?: string
  drugAllergies?: string
}

export type ChasFormClinicDetails = {
  referringClinic: {
    name?: string
    hci?: string
    address?: string
    licenseNumber?: string
    clinicType?: string

    // TODO - should we move these together with the "doctor" sub-object?
    contactNo: string
    email: string
  }
  doctor: {
    name?: string
    mcr?: string
  }
}

export const extractChasPatientInfo = (
  referral: PublicReferralDto,
): ChasFormPatientDetails => {
  return {
    ...referral.patient,
    healthierSGEnrolmentStatus: extractStringByQuestion({
      questionFragment: 'healthier sg',
      referral,
    }),
    clinicEnrolmentStatus: extractStringByQuestion({
      questionFragment: 'is the patient enrolled in your clinic',
      referral,
    }),
    chasStatus: referral.patient.chasStatus,
  }
}

export const extractChasReferralInfo = (
  referral: PublicReferralDto,
): ChasFormReferralDetails => {
  return {
    referralDate: referral.createdAt,
    referralType: extractStringByQuestion({
      questionFragment: 'type of referral',
      referral,
    }),
    fastTrackReferralReason: extractStringByQuestion({
      questionFragment: 'Reasons for Fast-track referral',
      referral,
    }),
    referredInstitutionOrProvider: extractStringByQuestion({
      questionFragment: 'Referred Institution or Provider',
      referral,
    }),
    specialtyOrService: referral.offering.name,
    referralRelation: extractStringArrayByQuestion({
      questionFragment: 'CHAS / SFL / VCDSS',
      referral,
    }),
    diagnosis: extractStringByQuestion({
      questionFragment: 'diagnosis',
      referral,
    }),
    reasons: extractStringByQuestion({
      questionFragment: 'Reasons for Referral',
      referral,
    }),
    treatmentOrManagementByGp: extractStringByQuestion({
      questionFragment: 'Treatment / Management',
      referral,
    }),
    medicalHistory: extractStringByQuestion({
      questionFragment: 'Medical History',
      referral,
    }),
    drugAllergies: extractStringByQuestion({
      questionFragment: 'Drug Allergies',
      referral,
    }),
  }
}

export const generateSenderClinicAddress = (
  sender: PublicReferralDto['sender'],
): string => {
  let floorUnit = ''
  if (sender.floorNumber && sender.unitNumber) {
    floorUnit = ` ${sender.floorNumber}-${sender.unitNumber},`
  } else if (sender.floorNumber) {
    floorUnit = ` Floor ${sender.floorNumber},`
  } else if (sender.unitNumber) {
    floorUnit = ` Unit ${sender.unitNumber},`
  }

  return `${sender.block} ${sender.streetName},${floorUnit} S${sender.postalCode}`
}

export const extractChasClinicInfo = (
  referral: PublicReferralDto,
): ChasFormClinicDetails => {
  return {
    referringClinic: {
      name: referral.sender.name,
      hci: referral.sender.hciCode,
      address: generateSenderClinicAddress(referral.sender),
      licenseNumber: extractStringByQuestion({
        questionFragment: 'Clinic Service License Number',
        referral,
      }),
      contactNo: referral.referringDoctor.contactNumber,
      email: referral.referringDoctor.email,
      clinicType: extractStringByQuestion({
        questionFragment: 'clinic type',
        referral,
      }),
    },
    doctor: {
      name: referral.referringDoctor.name,
      mcr: referral.referringDoctor.mcr,
    },
  }
}
