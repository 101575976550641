import { Button, ButtonGroup, Flex } from '@chakra-ui/react'
import { Banner } from '@opengovsg/design-system-react'

import { AcknowledgeForm } from './AcknowledgeForm'
import { SubmitAcknowledgementButton } from './SubmitAcknowledgementButton'
import { useZoomState, ZoomButtonGroup } from './ZoomButtonGroup'

import { HiddenPrintLayout } from '@/components/HiddenPrintLayout'
import { DefaultReferralView } from '@/features/public-referral/DefaultReferralView/DefaultReferralView'
import { ReferralDto } from '~shared/types'

export const AcknowledgeUnsubsidised = ({
  referral,
}: {
  referral: ReferralDto
}) => {
  const { zoom, onZoomOut, onZoomIn } = useZoomState()

  return (
    <HiddenPrintLayout
      printOnlyChildren={
        <Flex
          w="fit-content"
          h="fit-content"
          bg="white"
          p="1.5rem"
          mx="auto"
          align="stretch"
        >
          <DefaultReferralView referral={referral} />
        </Flex>
      }
    >
      <Banner>
        {`Please review the patient's referral form, then accept or reject the
          referral.`}
      </Banner>
      <Flex h="calc(100vh - 116px)" overflow="hidden">
        <Flex
          h="full"
          flex="1"
          bg="base.canvas.backdrop"
          overflow="auto"
          p="2rem"
        >
          <Flex
            w="fit-content"
            h="fit-content"
            bg="white"
            p="1.5rem"
            mx="auto"
            align="stretch"
            style={{ zoom: `${zoom}%` }}
          >
            <DefaultReferralView referral={referral} />
          </Flex>
          <Flex
            boxShadow="0px -1px 6px rgba(0, 0, 0, 0.15)"
            padding="1rem"
            right={0}
            left={0}
            bottom={0}
            position="fixed"
            zIndex="sticky"
          >
            <ZoomButtonGroup
              onZoomOut={onZoomOut}
              onZoomIn={onZoomIn}
              mx="auto"
            />
            {/* 20 rem is flexible - but is greater than buttongroup's innate width. 
            This prevents zoom buttons from shifting when submit button widths change */}
            <ButtonGroup alignSelf="end" width="20rem" justifyContent="end">
              <Button variant="outline" onClick={() => window.print()}>
                Save as PDF
              </Button>
              <SubmitAcknowledgementButton referral={referral} />
            </ButtonGroup>
          </Flex>
        </Flex>
        <Flex w="400px" overflowY="auto" p="1.5rem" pb="2rem">
          <AcknowledgeForm referral={referral} />
        </Flex>
      </Flex>
    </HiddenPrintLayout>
  )
}
