import { IsBooleanString, IsNumber, IsOptional } from 'class-validator'

import { GetFormFieldRes } from '../form-fields'

import { InstitutionCoreDto } from './institution.dto'

export const subsidisableMap: Record<EligibilityScheme, boolean> = {
  'subsidy-chas-all': true,
  'non-subsidisable': false,
}

export type OfferingMetadata = {
  phas?: {
    offeringId: number
    institutionId: number
  }
  pb?: {
    institutionId: string
    institutionCode: string
    specialtyId: string // e.g. 1d60996a-49c0-4cb0-88a3-0ce3ad0364fe
    specialtyCode: string // e.g. TOX
    subSpecialtyId?: string // e.g. 28600b58-2785-48db-ba79-5752cb05a611
    subSpecialtyCode?: string // e.g. OTOX
  }
  ea?: {
    location?: string
    destinationEmail?: string
  }
}

export class GetTimeslotsQuery {
  @IsNumber()
  from: number

  @IsOptional()
  @IsBooleanString()
  isSubsidised?: boolean
}

export type Timeslot = {
  from: number
  to: number
}

export type ListOfferingsRes = OfferingCoreDto[]

// GET offerings (list all)
// should contain enough information to display a list of offerings
export type OfferingCoreDto = {
  id: string
  name: string
  institutionHciCode: string
  specialty: string
  institution: InstitutionCoreDto
  // isActive: boolean - always true
}

// GET offerings/:id
export type OfferingFullDto = OfferingCoreDto & {
  formFields: GetFormFieldRes[]
  isUrgentable: boolean
  isSubsidisable: boolean
  shouldShowTimeslots: boolean
  formVariant: string | null
}

export const ELIGIBILITY_SCHEMES = [
  // Accepts any CHAS variant, including MG PG and PA card. Used for most PB offerings.
  'subsidy-chas-all',
  // Subsidy is not applicable. Used for HAS offerings.
  'non-subsidisable',
]

export type EligibilityScheme = (typeof ELIGIBILITY_SCHEMES)[number]
