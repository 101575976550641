import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, VStack } from '@chakra-ui/react'
import { FormLabel, Textarea } from '@opengovsg/design-system-react'

import { EaFormFields } from '../../email-ack.types'

import { DateTimeField } from './DateTimeField'

export const AcceptField = () => {
  const { control } = useFormContext<EaFormFields>()

  return (
    <VStack gap={5} alignItems="stretch">
      <DateTimeField />
      <FormControl>
        <FormLabel description="We will send this to the GP.">
          Notes for GP doctor
        </FormLabel>
        <Controller
          name="noteContent"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              placeholder="Enter your notes here"
            />
          )}
        />
      </FormControl>
    </VStack>
  )
}
