import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'
import 'reflect-metadata'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@opengovsg/design-system-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { DdRum } from '@/features/monitoring/DdRum'
import { appRoutes } from '@/routes/app.routes'
import { theme } from '@/theme'
import { queryClient } from '@/utils/query'

const router = createBrowserRouter(appRoutes)

export const App = () => (
  <QueryClientProvider client={queryClient}>
    {/* Only enabled on development*/}
    <ReactQueryDevtools initialIsOpen={false} />
    <ThemeProvider theme={theme}>
      <DdRum>
        <RouterProvider router={router} />
      </DdRum>
    </ThemeProvider>
  </QueryClientProvider>
)
