import { useFormContext } from 'react-hook-form'
import { Divider, Heading, Image, StackProps, VStack } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { EaDecisionEnum, EaFormFields } from '../../email-ack.types'

import { AcceptField } from './AcceptField'
import { DecisionField } from './DecisionField'
import { PatientDetails } from './PatientDetails'
import { RejectField } from './RejectField'

import { ReferralDto } from '~shared/types'

export const AcknowledgeForm = ({
  referral,
  ...props
}: { referral: ReferralDto } & StackProps) => {
  const { watch } = useFormContext<EaFormFields>()
  const decision = watch('decision')

  return (
    <VStack h="fit-content" spacing="2rem" divider={<Divider />} {...props}>
      <VStack alignItems="stretch">
        <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
        <Heading fontSize="24px" color="base.content.strong" textAlign="center">
          Referral to {referral.offering.name}
        </Heading>
      </VStack>
      <PatientDetails referral={referral} />
      <DecisionField referral={referral} />
      {decision === EaDecisionEnum.REJECT && <RejectField />}
      {decision === EaDecisionEnum.ACCEPT && <AcceptField />}
    </VStack>
  )
}
