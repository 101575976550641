import { Flex, StackProps, VStack } from '@chakra-ui/react'

import { useBreakpointValue } from '@/hooks/useBreakpointValue'

export const PlainAcknowledgeLayout = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & StackProps) => {
  return (
    <Flex
      w="full"
      h="full"
      flex="1"
      justifyContent="center"
      bg="base.canvas.alt"
      p={useBreakpointValue({ base: '0', sm: '2rem' })}
    >
      <VStack
        w="40rem"
        bg="white"
        borderRadius={useBreakpointValue({ base: 'none', md: 'md' })}
        p="2rem"
        align="stretch"
        spacing="1rem"
        minH={useBreakpointValue({ base: '100vh', md: 'auto' })}
        {...props}
      >
        {children}
      </VStack>
    </Flex>
  )
}
