import { Divider, Heading, Image, Text } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { PatientDetailsBody } from './Acknowledge/PatientDetails'
import { NeedToMakeAChange } from './NeedToMakeAChange'
import { PlainAcknowledgeLayout } from './PlainAcknowledgeLayout'

import { ReferralDto } from '~shared/types'

export const Rejected = ({ referral }: { referral: ReferralDto }) => {
  return (
    <PlainAcknowledgeLayout>
      <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
      <Heading fontSize="24px" color="base.content.strong" textAlign="center">
        Referral rejected
      </Heading>
      <Text color="base.content.default" textAlign="center">
        We will notify the GP doctor of the referral outcome. You may close this
        page.
      </Text>
      <Divider />

      <PatientDetailsBody referral={referral} />
      <NeedToMakeAChange mt="2rem" />
    </PlainAcknowledgeLayout>
  )
}
