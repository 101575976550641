import { useEffect, useMemo } from 'react'
import { useRouteError } from 'react-router-dom'
import { Flex, Image, Link, Text } from '@chakra-ui/react'

import SceneDisconnectedSvg from '@/assets/scene-disconnected.svg'
import { datadogGetSessionId, logRumError } from '@/services'

const STACK_TRACE_PREFILL_FIELD_ID = '64daeee8d27e070012f96fb0'
const DD_SESSION_ID_PREFILL_FIELD_ID = '64daef065154380012408a44'
const ERROR_REPORTING_FORMSG_URL =
  'https://form.gov.sg/670df9deee9a344a59d913b3'

export const ErrorCard = () => {
  const error = useRouteError()
  const ddSessionId = datadogGetSessionId()

  const message = useMemo(() => {
    const urlWithParams = new URL(ERROR_REPORTING_FORMSG_URL)
    if (error) {
      urlWithParams.searchParams.append(
        STACK_TRACE_PREFILL_FIELD_ID,
        (error as Error).stack || '',
      )
    }
    if (ddSessionId) {
      urlWithParams.searchParams.append(
        DD_SESSION_ID_PREFILL_FIELD_ID,
        ddSessionId,
      )
    }
    return (
      <Text>
        {`Try refreshing the page or clearing your browser's cache. If the problem persists, `}
        <Link href={urlWithParams.href} target="_blank" rel="noreferrer">
          <u>let us know</u>.
        </Link>
      </Text>
    )
  }, [error, ddSessionId])

  // useEffect runs once per render of the card.
  useEffect(() => {
    logRumError({ error, context: { reason: 'errorcard displayed' } })
  }, [error])

  return (
    <Flex
      flexDirection="column"
      justifyContent="center" // Center vertically
      alignItems="center" // Center horizontally
      flex={1}
      height="100%"
      padding={8}
      gap={8}
      textStyle="body-2"
    >
      <Image
        src={SceneDisconnectedSvg}
        alt="Error"
        maxW={`min(45rem, 100%)`}
        maxH="100%"
      />
      <Text textStyle="h5">{'Looks like something went wrong'}</Text>
      <Text>{message}</Text>
      {ddSessionId && (
        <Text textStyle="code-1">{`Session ID: ${ddSessionId}`}</Text>
      )}
    </Flex>
  )
}
