import { Box } from '@chakra-ui/react'

const HIDDEN_CLASS = 'hidden-class'
const SHOW_CLASS = 'show-class'

/**
 * A layout component that hides the printOnlyChildren on print.
 *
 * @param printOnlyChildren - The children to be displayed only when printing.
 * @param children - The children to be displayed when not printing.
 * @returns A layout component that hides the printOnlyChildren on print.
 */
export const HiddenPrintLayout = ({
  printOnlyChildren,
  children,
}: {
  printOnlyChildren: React.ReactNode
  children: React.ReactNode
}) => {
  const printStyles = `
   .${SHOW_CLASS} {
     display: initial;
   }
   @media print {
     .${SHOW_CLASS} {
       display: none;
     }
   }
   .${HIDDEN_CLASS} {
     display: none;
   }
   @media print {
     .${HIDDEN_CLASS} {
       display: initial;
     }
   }`

  return (
    <>
      <style>{printStyles}</style>
      <Box className={HIDDEN_CLASS}>{printOnlyChildren}</Box>
      <Box className={SHOW_CLASS}>{children}</Box>
    </>
  )
}
