import { Divider, Heading, Image, Text } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { PatientDetailsBody } from './Acknowledge/PatientDetails'
import { PlainAcknowledgeLayout } from './PlainAcknowledgeLayout'

import { ReferralDto } from '~shared/types'

export const Withdrawn = ({ referral }: { referral: ReferralDto }) => {
  return (
    <PlainAcknowledgeLayout>
      <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
      <Heading fontSize="24px" color="base.content.strong" textAlign="center">
        This referral has been withdrawn by the referring GP doctor
      </Heading>
      <Text color="base.content.default" textAlign="center">
        No further action is required from you.
      </Text>
      <Divider />
      <PatientDetailsBody referral={referral} />
    </PlainAcknowledgeLayout>
  )
}
