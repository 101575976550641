import { useSearchParams } from 'react-router-dom'
import { Button, ButtonProps } from '@chakra-ui/react'

import { ACTION_HINT, ACTION_HINT_URL_KEY } from '../email-ack.constants'

export const NeedToMakeAChange = (props: ButtonProps) => {
  const [query, setQuery] = useSearchParams()

  const onClick = () => {
    const newQuery = new URLSearchParams(query)
    newQuery.set(ACTION_HINT_URL_KEY, ACTION_HINT.CHANGE)
    setQuery(newQuery)
  }

  return (
    <Button
      variant="link"
      textDecoration="underline"
      color="base.content.default"
      mx="auto"
      onClick={onClick}
      {...props}
    >
      Need to make a change?
    </Button>
  )
}
