import { useParams, useSearchParams } from 'react-router-dom'

import { Acknowledge } from './components/Acknowledge/Acknowledge'
import { Confirmed } from './components/Confirmed'
import { ErrorInAcknowledgement } from './components/ErrorInAcknowledgement'
import { Rejected } from './components/Rejected'
import { Withdrawn } from './components/Withdrawn'
import { ACTION_HINT, ACTION_HINT_URL_KEY } from './email-ack.constants'

import { LoadingScreen } from '@/components/LoadingScreen'
import { useGetReferral } from '@/hooks/useGetReferral'
import { useNamedDocumentTitle } from '@/hooks/useNamedDocumentTitle'

export const EmailAckPage = () => {
  const { referralId } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('auth') ?? ''
  const actionHint = searchParams.get(ACTION_HINT_URL_KEY) ?? ''

  const {
    data: referral,
    isPending,
    isError,
  } = useGetReferral({ referralId: referralId ?? '', token })

  useNamedDocumentTitle(referral)

  if (isPending) return <LoadingScreen />
  if (isError) return <ErrorInAcknowledgement />

  if (
    actionHint === ACTION_HINT.CHANGE &&
    [
      'pending-email-ack',
      'confirmed',
      'rejected',
      'pending-cancelled',
      'cancelled',
    ].includes(referral.status)
  ) {
    return <Acknowledge referral={referral} />
  }

  switch (referral.status) {
    case 'pending-email-ack':
      return <Acknowledge referral={referral} />
    case 'confirmed':
      return <Confirmed referral={referral} />
    case 'rejected':
      return <Rejected referral={referral} />
    case 'pending-cancelled':
    case 'cancelled':
      return <Withdrawn referral={referral} />
    default:
      return <ErrorInAcknowledgement />
  }
}
