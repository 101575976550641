import { Heading, Image, Link, Text } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { PlainAcknowledgeLayout } from './PlainAcknowledgeLayout'

import { HAS_SUPPORT_EMAIL } from '~shared/team.constants'

export const ErrorInAcknowledgement = () => {
  const href = `mailto:${HAS_SUPPORT_EMAIL}`

  return (
    <PlainAcknowledgeLayout>
      <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
      <Heading fontSize="24px" color="base.content.strong" textAlign="center">
        {`This URL doesn't look right`}
      </Heading>
      <Text color="base.content.default" textAlign="center">
        Please write in to <Link href={href}>{HAS_SUPPORT_EMAIL}</Link> and we
        will send you the correct link.
      </Text>
    </PlainAcknowledgeLayout>
  )
}
