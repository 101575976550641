import { IS_DEVELOPMENT_ENV } from '@/constants/config'

export const MASK_COMPONENT_ON_MONITORING_PROPS = {
  'data-dd-privacy': 'mask',
}

// https://ogp-healthtech.datadoghq.com/rum/application/bde6a98a-ae0b-49c2-b0a8-821b88dd0c8e/manage
export const DATADOG_APPLICATION_ID = IS_DEVELOPMENT_ENV
  ? null
  : 'bde6a98a-ae0b-49c2-b0a8-821b88dd0c8e'
export const DATADOG_CLIENT_TOKEN = IS_DEVELOPMENT_ENV
  ? null
  : 'pub214681535e20d5a969d3768e152cc872'
